export default {
  methods: {
    titleCase(str) {
      let strArr = str.split(" ");
      for (let i = 0; i < strArr.length; i++) {
        strArr[i] =
          strArr[i].substring(0, 1).toUpperCase() +
          strArr[i].toLowerCase().substring(1);
      }
      return strArr.join(" ");
    }
  }
};
